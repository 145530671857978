import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSubscriptionAuth } from '../context/subscriptionAuthContext';
import Loading from '../components/loading';
import Layout from '../components/layout';

const NotFoundPage = () => {
    const { loggedIn } = useSubscriptionAuth();

    useEffect(() => {
        // Handle login navigation
        const route = loggedIn ? '/subscribe' : '/';
        navigate(route, { replace: true });
    }, [loggedIn]);

    return (
        <Layout>
            <div className="page-wrapper">
                <Loading />
            </div>
        </Layout>
    );
};

export default NotFoundPage;
